#box_productfull {
    &.productfull {
        width: 100%;
        margin: 0 auto;
        .maininfo {
            background-color: @greyColor;
            border: 1px solid @border;
            margin-bottom: 30px;
            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
            }

            &__column {
                flex-basis: calc((100% / 2) + 2%);
                flex-grow: 1;
                padding: 30px;

                @media screen and (max-width: @sm) {
                    flex-basis: 100%;
                    padding: 30px 15px;
                }

                &--info {
                    flex-basis: calc((100% / 2) - 2%);
                }
            }

            
            .boxhead {
                display: flex;
                flex-direction: column;

                &__name {
                    font-size: 26px;
                    font-weight: bold;
                    text-align: left;
                    line-height: 1.231;
                }

                &__code {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    padding: 0 0 15px;

                    font-size: 14px;
                    text-align: left;
                    line-height: 1.429;

                    .code {
                        margin-right: 15px;
                    }
                }

            }
        }

    }
}