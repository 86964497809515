.aside-menu {
    &#box_menu {
        .innerbox {
            margin-top: 20px;
            ul.standard {
                >li {
                    font-size: 13px;
                    line-height: 1.2;
                    margin-bottom: 5px;

                    >a {
                        display: inline-block;
                        margin-bottom: 10px;
                        color: black;
                    }

                    &.current, &.current_parent { 
                        >a {
                            font-weight: bold;
                            color: @mainColor;
                        }
                    }
                    ul {
                        margin-left: 5px;

                        li {
                            font-size: 13px;
                            list-style-type: none;

                            &.current {
                                >a {
                                    color: black;
                                    position: relative;
                                    &::before { 
                                        content: '\e912';
                                        font-family: Frino;
                                        background-color: @mainColor;
                                        color: white;
                                        border-radius: 50%;

                                        position: absolute;
                                        left: -15px;
                                        top: 5px;
                                        padding: 0;
                                        height: 10px;
                                        width: 10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        font-size: 7px;
                                        line-height: 1.2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}