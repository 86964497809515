.blog-article {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @media screen and (max-width: @sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__thumbnail {
        width: 100%;
        max-width: 245px;
        min-width: 200px;
        height: 320px;
        background-size: cover;
        background-position: center;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        &:hover {
            .blog-article__thumbnail-link {
                opacity: 1;
                color: white;
            }
        }
    }

    &__thumbnail-link {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        background-color: rgba(242, 0, 16, .6);

        color: white;
        font-size: 18px;
        font-weight: bold;

        opacity: 0;

        transition: opacity .3s ease-in-out;

        &::after {
            content: '\e912';
            font-family: Frino;
            font-weight: bold;
            padding: 0 0 0 5px;
        }
    }

    &__content {
        padding: 50px 45px;
        @media screen and (max-width: @sm) {
            padding: 25px 15px;
        }
    }

    &__header {
        .blog-article__date {
            font-size: 16px;
        }
        .blog-article__title {
            font-size: 24px;
            font-weight: bold;
        } 
    }

    &__excerpt {
        font-size: 13px;
        line-height: 1.38;

        .blog-article__read-more {
            display: flex;
            justify-content: flex-end;

            text-align: right;
            font-size: 12px;
            font-weight: bold;
            margin-top: 1rem;
            &::after {
                content: '\e912';
                font-family: Frino;
                font-weight: bold;
                color: @mainColor;
                padding: 0 0 0 2px;
            }
        }
    }

}