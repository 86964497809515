.about {
    width: 100%;
    min-height: 610px;
    padding: 45px 50px;

    color: white;

    background-size: cover !important;
    background-position: center !important;

    border-radius: 5px;
    
    @media screen and (max-width: @md) {
        padding: 45px 25px;
    }

    &__header {
        padding: 0;
        .about__header-top {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            @media screen and (max-width: @md) {
                flex-direction: column;
            }
            .about__logo {
                margin-right: 50px;
                @media screen and (max-width: @md) {
                    margin: 0 0 20px 0;
                }
            }
        }
        .about__title {
            font-size: 22px; 
            font-weight: bold;
            line-height: 1.2;
        }
    }

    &__content { 
        margin-bottom: 20px;
        p {
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 1em;
        }
    }

    &__footer {
        .about__footer-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 1.5em;
        }
        .about__footer-columns {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .about__list {
            li {
                font-size: 14px;
                margin: 0 0 10px 25px;
                position: relative;
                &::before {
                    content: "•";
                    color: @mainColor;
                    font-size: 40px;
                    font-weight: bold;
                    position: absolute;
                    top: 1.5px;
                    left: -25px;
                }
            }
        }
    }
}

