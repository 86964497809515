h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
}


.text {
    &--red {
        color: @mainColor; 
    }
}


.module {
    &__header {
        display: flex;
        width: 100%;
        padding: 0 0 10px 0;
        margin: 0 0 30px 0;
        border-bottom: 1px solid @border;
        position: relative;

        .module__title {
            font-size: 36px;
            padding: 0 15px;
            margin: 0;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 6px;
                background-color: @mainColor;
 
                border-radius: 5px;

                position: absolute;
                bottom: -13px;
                left: 0;
            }
        }

        &--related {
            .module__title {
                font-size: 30px;
            }
        }
    }
}