.credits {
    width: 100%;
    border-top: 1px solid @border;

    &__wrapper {
        width: 100%;
        padding: 0 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: @lg) {
            flex-direction: column;
        }
    }

    .credit {
        margin: 0 10px;
        font-size: 12px;

        .payments {
            text-align: center;
            &--logo {
                margin: 10px;
            }
        }

        &--copyright {
            display: flex;
            align-items: baseline;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
        }

        .shoper {
            margin: 0 5px;
        }

        .automotohit {
            font-weight: bold;
            margin: 0 5px;
        }

        .getecom {
            margin: 0 5px 0 25px;
            display: inline-block;
            vertical-align: middle;
            width: 129px;
            transition: 0.3s ease-in-out;

            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);

            &:hover {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }
    }

}