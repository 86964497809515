#box_filter {
    .innerbox {
        .filter-name {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            padding: 0 15px 10px;
        }
        .multiselect {
            border: 1px solid @border;
            border-radius: 40px;
            width: 100%;
            background: white;
            font-size: 13px;
            font-weight: 500;
            color: black;

            margin-bottom: 30px;

            position: relative;

            z-index: 11;

            &:hover {
                border-color: @mainColor;
                background: transparent;
                color: black;
            }

            &::before {
                content: '\e910';
                font-family: Frino;
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                background: @mainColor;
                color: white;
                border-radius: 50%;
                position: absolute;
                width: 20px;
                height: 20px;
                top: 11px;
                right: 15px;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

#box_producers {
    .innerbox {
        .producers_wrap {
            border: 0;
            width: 100%;

            .singleselect {
                border: 0;
                width: 100%;
            }
        }
    }
}

#filter_price {
    width: 100%;
    > span {
        display: none;
    }
    > ul {
        display: block!important;

        .priceinput {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .filter_price_wrapper {
                flex-basis: 50%;
                text-transform: uppercase;
                font-size: 11px;
                line-height: 1.2;
                margin-top: 15px;
                .filter_price_preview {
                    font-size: 12px;
                    font-weight: bold;
                }
            } 

            .bottombuttons {
                flex-basis: 50%; 
                margin-top: 15px;
            }
        }
    }
    .ui-slider {
        width: 94%;
        margin: 3px 3% 10px;
        height: 2px;
        .ui-slider-handle {
            background: @mainColor;
            border: 1px solid @mainColor;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            margin-top: -3.5px;
        }
    }

}