.breadcrumbs {
    padding: 0;
    transition: .3s ease-in-out;
    margin-bottom: 20px;
    .innerbreadcrumbs {
        padding: 0 15px;
        .breadcrumb-home {
            display: none;

            img {
                display: none;
            }
        }

        .path {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            font-weight: 500;

            li {
                padding: 0;
                display: inline-block;
                font-size: 12px;
                text-transform: uppercase; 
                .raq {
                    > i {
                        color: @mainColor;
                    }
                }

                &.bred-1 {
                    order: -1;

                    .raq {
                        display: none;
                    }
                }

                &.last {
                    font-weight: bold;

                    .raq {
                        color: black;
                    }
                }

                span { 
                    &.raq {
                        font-weight: normal;
                        margin: 0 7px 0;
                    }
                }
            }
        } 
    }
}