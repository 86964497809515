.contact {
    width: 100%;

    &__wrapper {
        width: 100%;
        max-width: 1150px;
        //  padding: 0 10px;
        margin: 20px auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @media screen and (max-width: @lg) {
            flex-direction: column;
        }
    }

    &__slogan,
    &__info {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-basis: calc((100% / 2) - 10px);
        flex-grow: 1;
        margin: 20px 5px;

    }

    &__slogan {
        max-width: 480px;
        @media screen and (max-width: @sm) {
            flex-direction: column;
            text-align: center;
        }
        .slogan__photo,
        .slogan__header {
            margin: 0 10px;
        }

        .slogan__title {
            margin: 0;
            font-size: 18px;
            font-weight: normal;
        }

        .slogan__subtitle {
            font-size: 24px;
            font-weight: bold;
            margin: 5px 0 0;
        }

    }

    &__info {
        font-size: 20px;
        max-width: 570px;

        @media screen and (max-width: @md) {
            flex-direction: column;
        }

        .info__column {
            flex-basis: calc((100%/2) - 10px);
            margin: 0 5px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                font-family: Frino;
                font-weight: bold;
                font-size: 24px;
                color: @mainColor;

                padding-right: 5px;
            }

            &.phones {
                @media screen and (max-width: @md) {
                    margin-bottom: 20px;
                }
                &::before {
                    content: '\e9cd';
                }

                .phones__top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap; 
                    font-size: 18px;
                    margin-bottom: 5px;
                    position: relative;

                    > a {
                        width: 105px;
                    }

                    &::before {
                        content: '';
                        height: 15px;
                        width: 1px;
                        background-color: @border;
                        position: absolute;
                        left: 50%;
                        top: 2px;
                    }
                }

                .phones__bottom {
                    font-size: 16px;
                }
            }

            &.mail {
                &::before {
                    content: '\e988';
                }
            }
        }

    }
}