.menu {
    position: relative;
    background-color: @greyColor;
    border: 1px solid @border;
    padding: 10px 15px !important;
    margin: 0 0 15px;

    &.navigation {
        background-color: transparent;
        border: 0;
        padding: 0 !important;
        margin: 0;

        position: sticky;
        top: 65px;

        @media screen and (max-width: @lg) {
            top: 0;
        }

        @media screen and (max-width: @md) {
            background-color: white;
            padding: 10px 15px !important;
            position: relative;
        }
    }




    @media screen and (max-width: 880px) {
        // background: fade( @backgroundColor, 90% );
        padding: 20px 0;
        //  border: 1px solid @lightBorderColor;
    }

    .innermenu {
        height: auto !important;
        line-height: unset !important;
        border: 0 !important;
    }

    &--main {
        @media screen and (max-width: @md) {
            display: none;
        }
    }



    .menu-list {
        overflow: unset !important;
        border-bottom: 1px solid @border  !important;
        height: 60px !important;
        transition: .4s ease-in-out;
    }

    &--sticky {
        .menu-list {
            padding: 5px 15px !important;
            height: 50px !important;
        }
    }

    // First level menu bar
    >.item {
        display: inline-block;
        float: none !important;



        >.item__link {
            display: block;
            text-align: center;
            padding: 5px 20px;
            color: black;

            @media screen and (max-width: 880px) {
                text-align: left;
            }

            transition: color 0.3s ease-in-out,
            background 0.3s ease-in-out;

            @media screen and (min-width: 881px) {
                &:hover {
                    //        color: @backgroundColor;
                    //  background: @mainColor;

                    opacity: 1;
                }
            }

            .item__label {
                font-size: 16px;
                font-weight: bold;
                line-height: 1;
            }
        }

        &.item--parent {
            &:first-of-type {
                border: 2px solid @mainColor;
                border-radius: 40px;

                >.item__link {
                    >.item__label {
                        font-size: 13px;
                    }
                }
            }

            >.item__link {
                transition: color 0.3s ease-in-out 0.3s,
                    background 0.3s ease-in-out 0.3s;
            }

            .item__icon {
                margin-left: 10px;
                transition: transform 0.3s ease-in-out 0.3s;
            }

            @media screen and (min-width: 881px) {
                &:hover {
                    >.item__link {
                        //       color: @backgroundColor;
                        //  background: @mainColor;

                        opacity: 1;

                        transition: color 0.3s ease-in-out,
                            background 0.3s ease-in-out;
                    }

                    .item__icon {
                        transform: rotate(180deg);
                        transition: transform 0.3s ease-in-out;
                    }

                    .submenu__wrapper {
                        visibility: visible;
                        opacity: 1;

                        transition: visibility 0s linear 0s,
                            opacity 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}

.submenu {
    display: flex !important;
    text-align: left;
    padding: 5px 15px 25px 15px;
    justify-content: space-between;

    @media screen and (max-width: 880px) {
        display: block !important;
        background: transparent;
        border: 0;
    }

    &__wrapper {
        background-color: @greyColor;
        border: 1px solid @greyColor;

        @media screen and (min-width: 767px) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            z-index: 100;

            visibility: hidden;
            opacity: 0;

            transition: visibility 0s linear 0.6s,
                opacity 0.3s ease-in-out 0.3s;
        }

        @media screen and (max-width: 880px) {}
    }


    .item {
        .item__link {
            display: block;

            padding: 5px 0;
            color: black;

            .item__label {
                font-size: 13px;
                font-weight: 400;
                line-height: 1.2;
            }
        }
    }

    .group {
        flex-basis: 25%;
        width: 100%;
        padding: 0 5px;

        &:last-child {
            flex-grow: 1;
        }

        &:not( :last-child) {
            //      border-right: 1px solid @lightBorderColor;
        }

        .group__header {

            //margin-bottom: 5px;
            @media screen and (max-width: 880px) {
                margin-bottom: 0;
            }

            .item__label {
                font-weight: 400;
            }
        }

        .group__items {
            @media screen and (max-width: 880px) {
                padding-left: 25px;
            }

            .item {
                &--parent {
                    >div {
                        display: none;
                    }
                }
            }
        }

        //  &[data-category-id="16"] {
        //      .group__items {
        //          columns: 2;

        //          @media screen and ( max-width: 880px ) {
        //              columns: unset;
        //          }
        //      }
        //  }
    }
}


.group__items {
    .item--parent {
        >div {
            display: none !important;
        }
    }
}