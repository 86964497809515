#box_productfull {
    .productinfo {
        &__wrapper {
            width: 100%;
            min-width: 345px;
            margin: 0 auto 3rem auto;

            @media screen and (max-width: @sm) {
                min-width: 270px;
            } 
          
            .price {
                margin-bottom: 25px;
                display: flex;
                align-items: baseline;
                &__name {
                    display: none;
                }
                &__main {
                    font-size: 35px;
                    font-weight: bold; 
                    color: @mainColor;
                    order: 1;
                }
                &__del {
                    font-size: 18px;
                    margin-right: 10px;
                }
            }

            .otherprice {
                margin-bottom: 15px;
            }
            .productavailability {
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                margin-bottom: 20px;

                @media screen and (max-width: @sm) {
                    flex-direction: column;
                }
                &__box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-basis: calc((100% / 2) - 10px);
                    margin-right: 10px;

                    font-size: 16px;
                    @media screen and (max-width: @sm) {
                        margin: 5px 0;
                    }

                    >.first {
                        margin-right: 5px; 
                        padding: 4px 0;
                    }

                    >.second {
                        font-size: 18px;
                        font-weight: bold;
                        padding: 4px 0;
                    }

                    &::before {
                        font-size: 30px;
                        font-family: Frino;
                        color: @mainColor;
                        padding-right: 5px;
                    }

                    &.availability {
                        &::before {
                            content: '\e751';
                        }
                    }
                    &.delivery {
                        &::before {
                            content: '\e9db';
                        }
                    }
                }
            }

            .bottomborder {
                .basket {
                    margin-top: 0;
                    .form-basket {
                        margin: 0;

                        .stocks {
                            .stock {
                                &__label {
                                    padding: 0 20px;
                                    >.label {
                                        font-size: 16px;
                                        font-weight: bold;
                                        line-height: 2.146;

                                        >.color {
                                            display: none;
                                        }
                                    }
                                }

                                &__options {
                                    width: 100%;
                                    max-width: 385px;
                                    margin-bottom: 15px;
                                    position: relative;
                                    @media screen and (max-width: @sm) {
                                        max-width: 250px;
                                    }
                                    .option_select {
                                        select {
                                            height: 45px;

                                            background: white;
                                            border: 1px solid @border;
                                            border-radius: 40px;
                                            padding: 0 15px;

                                            font-size: 13px;
                                            color: black;

                                            transition: .3s ease-in-out;

                                            &:hover {
                                                color: black;
                                                border-color: @mainColor;
                                            }
                                        }
                                        &::after {
                                            content: '\e910';
                                            font-family: Frino;
                                            font-size: 11px;
                                            font-weight: bold;
                                            color: white; 
                                            line-height: 1;
                                            position: absolute;
                                            top: 13px;
                                            right: 20px;
                                            background-color: @mainColor;
                                            border-radius: 50%;

                                            padding: 4px;
                                            pointer-events: none;
                                        }
                                    }

                                    .option_color {
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        .addtobasket-container {
                            margin: 30px 0 20px;
                            @media screen and (max-width: @sm) {
                                display: flex;
                                flex-direction: column;
                            }

                            .quantity_wrap {
                                width: 20%;
                                margin-right: 3%;

                                @media screen and (max-width: @sm) {
                                    margin-bottom: 20px;
                                }

                                .number-wrap {
                                    #incdec {
                                        display: flex;
                                        align-items: center;

                                        font-size: 24px;
                                        font-weight: 500;
                                        line-height: 1.25;

                                        #up,
                                        #down {
                                            cursor: pointer;
                                        }

                                        .product-quantity {
                                            margin: 0 5px;

                                            width: 45px !important;
                                            min-width: 45px !important;
                                            height: 45px;
                                            text-align: center;
                                            color: black;

                                            font-size: 14px;
                                            font-weight: 500;

                                            border: 1px solid @border;
                                            border-radius: 5px;
                                            background-color: white;
                                        }


                                    }

                                    .unit {
                                        display: none;
                                    }
                                }
                            }

                            .button_wrap { 
                                width: 77%;

                                @media screen and (max-width: @xs) {
                                    width: 67%;
                                    margin-left: 10%;
                                    min-width: unset;
                                }

                                .addtobasket {
                                    font-family: Roboto, sans-serif;
                                    text-transform: lowercase;
                                    font-size: 18px;
                                    max-width: 275px;
                                    border-radius: 40px;
                                    height: 45px;
                                }
                            }
                        }
                    }
                }

                .productbottom {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;

                    font-size: 14px;
                    font-weight: bold;

                    line-height: 1.2;

                    &__link {
                        display: flex;
                        align-items: center;

                        margin: 0 7px;

                        font-size: 14px;

                        &::before {
                            font-family: Frino;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 1;
                            color: @mainColor;

                            margin-right: 2px;
                        }

                        &--question {
                            &::before {
                                content: '\e92f';
                            }
                        }

                    }
                    .fb_buttons {
                        margin: 0 7px;
                    }
                    
                }
            }

        }


    }
}