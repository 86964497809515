.blog-single__content {
    padding: 0 2rem!important;
}

.single-article {
    &__info-wrapper {
        position: relative;
        margin: 1rem 0;
        min-height: 50px;
    }
    &__image {
        .article-image {
            object-fit: cover;
            height: 450px;
            width: 100%;
            border-radius: 5px;
            @media screen and (max-width: @sm) {
                height: 250px;
            }
        }
    }
    &__info {
        margin: 10px 0;
        .article-date, .article-categories {
            color: white;
            font-size: 14px; 
            font-weight: bold;
            border-radius: 40px; 
            padding: 5px 25px 5px 15px;
            margin: 0 10px;
            > a {
                color: white;
            }
        }
        .article-date {
            background: @mainColor; 
        }
        .article-categories { 
            background: black;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .article-name {
            font-size: 2.3em;
            line-height: 1; 
            margin: 0;
        } 
    }
    &__author {
        color: black;
        font-size: 14px;
        text-align: center;
        margin: 5px 0 0 0;
        padding: 15px 0;
        border-bottom: 1px solid @border;
        .article-author {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .article-content {
        p {
            font-size: 16px;
            line-height: 1.5;
        }
        img {
            height: auto;
        }
    }
} 