.secondNavigation {
    &__wrapper {
        overflow: hidden;
    }
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -22px;
        @media screen and (max-width: @lg) {
            flex-wrap: wrap;
        }
        @media screen and (max-width: @md) {
            display: none;
        }
        .secondNavigation__item {
            display: block;
            width: 100%;
            min-width: 140px;
            max-width: 180px;

            margin: 0 10px 15px 10px;

            border: 1px solid @border;
            border-radius: 5px; 

            transition: border-color .3s ease-in-out;
            &:hover {
                border-color: @mainColor;
            }

            .secondNavigation__link { 
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                min-height: 220px;
                padding: 0 15px;

                .secondNavigation__image-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 150px;
                }
                .secondNavigation__label {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 17px;
                    font-weight: bold;
                    line-height: 1.176;
                    text-align: left;
                    
                    &::after {
                        content: '\e912';
                        font-family: Frino;
                        font-size: 14px;
                        color: white;
                        background-color: @mainColor;
                        border-radius: 50%;
                        margin-left: 5px;
                        padding: 5px 6px;
                    }
                }
            }
        }

    }
}