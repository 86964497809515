.button {
    transition: .3s ease-in-out;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid transparent;

    &:hover {
        opacity: .8;
    }

    &--mid {
        font-size: 14px;
        padding: 7px 27px;
        border-radius: 40px;
    }
    
    &--big {
        font-size: 16px;
        padding: 15px 35px;
        border-radius: 45px;
    }

    &--white {
        background-color: white;

        color: black;
        border-color: @mainColor;
        &:hover {
            background-color: @mainColor;
            color: white;
        }
    }

    &--red {
        color: white;
        background-color: @mainColor;

        &:hover {
            color: white;
        }
    }

    &--icon {
        font-size: 24px;

        >i {
            &::before {
                font-weight: bold;
            }
        }
    }
}