.box-slider {
    margin-bottom: 50px;
    .pageslider {
        .ps-arrow-left, .ps-arrow-right {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-top: -2em;
            background: white!important;
            display: flex;
            justify-content: center;
            align-content: center;
            transition: .3s ease-in-out;
            &::before {
                line-height: 1;  
                font-size: 24px;
                display: flex;
                justify-content: flex-start; 
                align-items: center;
                margin-right: 0;
            }
            &:hover {
                box-shadow: 0px 0px 55px -10px rgba(0,0,0,0.65);
            }
        }
          
        .ps-arrow-left {
            left: -2.5em!important;
            justify-content: flex-end;

            &::before {
                content: '\e911';
                font-family: Frino;
                padding-right: 5px;
            }
        }
        .ps-arrow-right { 
            right: -2.5em!important; 
            justify-content: flex-start;
            &::before {
                content: '\e912';
                font-family: Frino;
                padding-left: 5px;
            }
        } 
        .slider-nav {
            bottom: 1em;
            overflow: hidden;
            position: absolute;
            text-align: center;
            width: 100%;
            &.bullets {
                li {
                    background: white;
                    &.active {
                        background: @mainColor;
                    }
                } 
            }
        }
    }

}