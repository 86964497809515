.site-header {
    padding: 17px 0;
    background-color: white;
    z-index: 999;

    top: 0;
    position: sticky;

    transition: .3s padding ease-in-out;

    @media screen and (max-width: @lg) {
        top: unset;
        position: relative;
    }

    @media screen and (max-width: @sm) {
        padding: 0 !important;
    }

    &--sticky {
        padding: 5px 0;
    }


    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 0 10px 5px 0;

        @media screen and (max-width: @sm) {
            flex-direction: column;
        }
    }

    .logo {
        width: 100%;
        max-width: 370px;

        &__image {
            position: relative;
            width: 100%;
            min-width: 250px;
            max-width: 368px;
        }

        h1 {
            margin: 0;
        }
    }

    .search-form {
        margin: 0;
        flex-grow: 1;
        max-width: 420px;
        padding: 0 10px;

        @media screen and (max-width: @sm) {
            margin-top: 10px;
        }

        .search__input-area {
            height: 50px;
            border: none;
            border-radius: 5px;
            background-color: white;
            box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.08);
            padding: 0 10px;

            .search__input[type="search"] {
                color: black;
                font-size: 14px;
                font-weight: bold;
                font-style: italic;

                &::placeholder {
                    color: black;
                }
            }

            .search__btn-search {
                width: 38px;
                height: 36px;
                background-color: @mainColor;
                border: 1px solid @mainColor;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 8px;
                padding: 8px;

                transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

                &:hover {
                    background-color: white;
                    color: @mainColor;
                }
            }
        }
    }

    .user-panels {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: bold;

        @media screen and (max-width: @md) {
            display: none;
        }

        .user-panel {
            padding: 0 20px;

            &--actions {
                border-right: 1px solid @border;

                .actions {
                    &__link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &__label {
                        margin-right: 8px;
                    }

                    &__icon {
                        font-size: 30px;
                    }
                }
            }

            &--basket {
                .basket {
                    display: flex;
                    align-items: center;

                    &__label {
                        .basket__text {
                            margin-right: 8px;
                        }
                    }

                    &__cart {
                        position: relative;

                        .basket__icon {
                            font-size: 30px;
                        }

                        .basket__count {
                            position: absolute;
                            top: -10px;
                            right: -16px;
                            background: @mainColor;
                            font-size: 13px;
                            color: white;
                            border-radius: 50%;
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}