.articles {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: @xl) {
        justify-content: center;
    }
    &__main, &__rest {
        .article {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }

            &__container {
                display: flex;
                align-items: center;
                @media screen and (max-width: @sm) {
                    flex-direction: column;
                }
            }

            &__thumbnail {
                background-repeat: no-repeat;
                width: 100%;
                max-width: 85px;
                height: 110px;
                background-size: cover;
                border-radius: 10px;
                position: relative;
            }

            &__date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                padding: 5px;

                font-size: 14px;
                text-align: center;
                color: white;
                
                &--year {
                    font-size: 15px;
                    font-weight: bold;
                }
            }

            &__content {
                .article__header {
                    padding: 0;
                    .article__title {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0 0 5px 0;
                   }
                }
                .article__excerpt {
                    font-size: 12px;
                    line-height: 1.5;
                }
               .article__read-more {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    font-weight: bold;
                    text-align: right;

                    margin-top: 4px;
                    
                    &::after {
                        content: '\e912';
                        font-family: Frino;
                        font-weight: bold;
                        color: @mainColor;
                        padding: 2px 0 0 5px;
                    }
               }
            }
        }
    }

    //only for main article
    &__main {
        width: 100%;
        max-width: 690px;
        min-width: 500px;

        @media screen and (max-width: @sm) {
            min-width: 250px;
        }
        .article {
            width: 100%;

            &__container {
                
                align-items: flex-start;
                position: relative;

                width: 100%;

                @media screen and (max-width: @md) {
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__thumbnail {
                max-width: 285px;
                height: 380px;
                @media screen and (max-width: @md) {
                    height: 225px;
                }
            }

            &__date {
                padding: 15px;
                font-size: 17px;
                opacity: .8;
                &--year {
                    font-size: 18px;
                }
            } 

            &__content {
                width: 100%;
                max-width: 420px;
                min-width: 300px;
                margin: 65px 0 25px -45px; 
 
                background-color: white;
                box-shadow: 0px 3px 32px 0px rgba(0, 0, 0, 0.08);
                border-radius: 10px;

                padding: 30px 35px 25px 35px;
                z-index: 1;

                position: relative;

                @media screen and (max-width: @md) {
                    margin: 25px 0 100px 0;
                }

               .article__header {
                    .article__title {
                        font-size: 16px;
                    }
               }
 
               .article__excerpt {
                    font-size: 13px;

                    .article__read-more {
                        margin-top: 7px;
                    }
               }

               .article__button {
                    position: absolute;
                    bottom: -100px;
                    left: auto;
                    right: auto;
                    transform: translate(50%, -50%);
                    @media screen and (max-width: @sm) {
                        left: 0;
                    }
               }


            }
        }
    }

    //only for rest articles
    &__rest {
        width: 100%;
        max-width: 450px;
        .article {
            &__thumbnail {
                margin-right: 25px;  
                @media screen and (max-width: @sm) { 
                    margin: 0 0 15px;
                    max-width: 100%;
                    height: 150px;
                }
            }
        }
    }
}