.tab-container {
    margin: 1rem 0 2rem 0;
    nav {
        ul {
            border-bottom: 1px solid @border;
            position: relative;

            display: flex;

            @media screen and (max-width: 575px) {
                flex-direction: column;
                padding: 0;
            }
            li {
                .boxhead { 
                    position: relative;
                    border: 0!important;

                    h3 {
                        margin: 0; 
                        padding: 0 5px 10px 5px;
                        font-size: 24px;
                        line-height: 1.2;
                        color: black; 
                        background: white;
                        position: relative;
                        @media screen and (max-width: 767px) {
                            font-size: 22px;
                        }
                    } 
                    &.active {
                        &::after {
                            content: '';
                            width: 100%;
                            height: 5px; 
                            border-radius: 5px;
                            background-color: @mainColor;
    
                            position: absolute;
                            left: 0;
                            bottom: -4px;
    
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .product-tabs {
        .product-tab {
            .innerbox { 
                padding: .5rem 1rem;

                .resetcss { 
                    p { 
                       font-size: 18px;
                       line-height: 1.667;
                       @media screen and (max-width: @md) {
                           font-size: 16px;
                           line-height: 1.4;
                       }
                    }
                    img {
                        //border-radius: 25px;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
            .product-attributes {
                table.table {
                    margin: 1rem 0;
                    td {
                        padding: 10px;
                        &:first-child {
                           // background-color: @bgColor;
                           // border-bottom: 1px solid  @borderColor3;
                        }
                    }
                }
            }
        }
    }
}