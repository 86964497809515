.site-footer {
    font-weight: 500;
    &__wrapper {
        border-top: 1px solid @border;
    }
    
    &__links {
        margin: 25px 0 20px 0;

        .links {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            width: 100%;
            margin: 0 auto;

            @media screen and (max-width: @lg) {
                padding: 2rem;
            }

            @media screen and (max-width: @xs) {
                padding: .5rem;
            }

            &__group {
                font-size: 14px; 

                display: flex;
                justify-content: center;
                width: 100%;
                min-width: 210px;
                max-width: 450px;
                margin: 0 5px 5px 5px;

                @media screen and (max-width: @lg) {
                    justify-content: flex-start; 
                    flex-basis: calc((100% / 2) - 10px);
                    flex-grow: 1;
                }
                &--4 {
                    flex-basis: calc((100% / 4) - 10px);
                } 
                &--5 {
                    flex-basis: calc((100% / 5) - 10px);
                }

                .links__header {
                    font-size: 18px;
                    font-weight: 700;

                    margin-bottom: 10px;
                }

                .link {
                    &__href {
                        line-height: 1.8;
                    }
                }


            }
        }
    }
}