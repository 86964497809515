.bar {
    @media screen and (max-width: @md) {
        display: none;
    }
    &__wrapper {
        display: flex; 
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid @border;
    }
    &__info {
        .info__list {
            display: flex;
            align-items: center;
        }
        .info__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
 
            margin-right: 35px;

            font-size: 12px;
            text-transform: uppercase;
        }
        .info__icon {
            font-size: 20px;
            color: @mainColor;
            margin-right: 10px;
        }
    }
    &__contact {
        .bar__button {
            padding: 3px 27px;
        }
    }
}