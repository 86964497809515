.side-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @media screen and (max-width: @lg) {
        max-width: 250px;
    }
    @media screen and (max-width: @md) {
        display: none;
    }
}