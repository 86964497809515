#box_productfull {
    .productfull {
        &__gallery {
            &.productimg {
                margin-left: 0;

                .mainimg {
                    width: 100%;
                    height: 545px;
                    display: block;
                    justify-content: center; 
                    align-items: center;
                    background: #fff;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    position: relative; 
                    overflow: visible;
                    .img-wrapper {  
                        width: 100%;
                        height: 100%; 
        
                        display: flex;
                        justify-content: center; 
                        align-items: center;

                        img {
                            max-height: 545px;
                        }
                    }
                } 
    
                .gallery-navigation {
                    position: absolute; 
                    right: 0;
                    top: 45%;
                    line-height: 1; 

                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    overflow: hidden;

                    .gallery-left,
                    .gallery-right {
                        
                        display: inline-block;
                        vertical-align: middle;
            
                        width: 60px;
                        height: 60px;
            
                        color: white;
                        font-size: 24px;
                        line-height: 60px;
                        text-align: center;
                        background: @mainColor; 
                        border-radius: 50%;
            
                        margin: 0;
            
                        transition: opacity 0.3s ease-in-out;
            
                        &:hover { 
                            opacity: 0.6;
                        }
                    }

                    .gallery-left {
                        margin-left: -30px;
                        > i { 
                            margin-right: -20px;
                        }
                    }
                    .gallery-right {
                        margin-right: -30px;
                        > i {
                            margin-left: -20px;
                        }
                    }
                }
    
                .smallgallery {
                    margin: 0 0 0 -5px;
                    ul {
                        li {
                            border: 0;
                            border-radius: 5px;
                            width: 90px; 
                            margin: 0 5px; 
                            position: relative;
                            a {
                                padding: 0;
                                width: 100%;
                                max-width: 90px;
                                height: 90px;
                                //border: 1px solid @borderColor2;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                background: white;

                                &::before {
                                    content: '';
                                     position: absolute; 
                                     z-index: 1;
                                     bottom: 0px; 
                                     left: 0;
                                     height: 4px;
                                     width: 100%;
                                     background: @mainColor;
                                     border-radius: 5px;
                                     display: none;
                                 }
          
                                 &:hover {
                                     &::before {
                                         display: block;
                                     } 
                                 }


                                > img {
                                    max-width: 80px;
                                    max-height: 80px;
                                }
                            }
                        }

                        &.with-nav {
                            margin: 0;
                        }
                    }

                    .smallgallery-left, .smallgallery-right {
                        width: 20px;
                        height: 20px; 
                        background: @mainColor;
                        color: white; 
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;
                        border: 0;
                        border-radius: 5px;
                        transition: opacity 0.3s ease-in-out;
                        &:hover {
                            opacity: 0.6;
                        }
                        
                    }

                    .smallgallery-left {
                        border-right: none;
                    }
                    .smallgallery-right {
                        border-left: none;
                    }
    
                }
            } 
        } 
    }
}

.shop-gallery .shop-gallery-preview {
    padding: .5em 3em;
}