.article-tags {
    &__header {
        .article-tags__title {
            font-size: 24px;
            margin: 0;
        }
    }
    &__tags {
        margin: .5rem 0 1.5rem;
        > li {
            padding: 0 1px!important;
            margin: 5px 0;
            > a {
                font-size: 10px!important;
                font-weight: bold;
                color: @mainColor;
                text-transform: uppercase;
                border: 1px solid @mainColor;
                border-radius: 3px;
                padding: 5px 8px;
            }
        }
    }
}