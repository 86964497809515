body {
    background-color: white;
}

*,
::after,
::before {
    box-sizing: border-box;
}

a {
    color: black;
    transition: opacity 0.3s ease-in-out;
    outline: 0;
    cursor: pointer;
}


img {
    max-width: 100%;
    //width: auto;
    //height: auto;  
}

header {
    border-top: 0;
}
.rwd header {
    @media screen and (max-width: @sm) {
        padding: 0;
    }
}
footer {
    margin: 0;
    padding: 0;
}

.main {
    @media screen and (max-width: @md) {
        margin: 0 1em!important;
    }
}


.container {
    width: 100%!important;
    &::before, &::after {
        display: none;
    }

    > .s-row {
        display: flex;
        justify-content: center;
    }
}


.module {
    margin-bottom: 50px;
    @media screen and (max-width: @sm) {
        margin-bottom: 25px;
    }

    &--about, &--contact { 
        margin-bottom: 0;
    }

    &--related {
        order: 1;
    }
}


.box {
    .boxhead { 
        border: 0;
    }
}


#shoper-foot {
    display: none;
}