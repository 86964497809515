.products {
    &--grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        width: 100%;

        margin: 0 0 20px 0;


    }
}