.sort-and-view {
    position: absolute;
    top: -7px;
    right: 0;
    @media screen and (max-width: @lg) {
        position: relative;
        margin: 0 1rem;
    }
    .sortlinks {
        margin: 0;

        .products-sort-container {
            .products-active-sort {
                display: flex;
                align-items: center;

                height: 45px;
                min-height: 45px;

                padding: 0;
                margin: 0;

                background: none;
                border: 0;

                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;

                &:hover {
                    background: none;
                    color: black;

                    >span {
                        border-color: @mainColor;
                    }
                }

                >span {
                 //   background-color: @greyColor;
                    margin-left: 20px;
                    height: 100%;
                    width: 100%;
                    width: 210px;

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    font-size: 13px;
                    font-weight: 500;
                    text-transform: none;

                    padding: 0 15px;
                    border: 1px solid @border;
                    border-radius: 40px;

                    position: relative;

                    &::after {
                        content: '\e910';
                        font-family: Frino;
                        font-size: 14px;
                        color: white;
                        line-height: 1;
                        position: absolute;
                        top: 15px;
                        right: 20px;
                        background-color: @mainColor;
                        width: 15px;
                        height: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}