.slider {
    &__products {
        overflow: hidden;
        &:hover {
            .owl-nav {
                opacity: 1;
            }
        }
        .owl-nav {
            width: 100%;
            opacity: 0;
            transition: opacity .5s ease-in;
        }
        .owl-prev, .owl-next {
            position: absolute;
            top: calc(50% - 35px);
    
            width: 60px;
            height: 60px;

            background-color: @mainColor!important;
            color: white!important;
            
            font-size: 24px!important;
            line-height: 40px; 
            text-align: center;
            border-radius: 50%!important;
            margin: 0!important;
            transition: all 0.3s ease-in-out;
            overflow: hidden; 
            &:hover {
                box-shadow: 0px 3px 16px 0px rgba(1, 1, 1, 0.24);
            }
    
        }
        .owl-prev {
            left: -30px;
            display: flex!important;
            justify-content: flex-end; 
            align-items: center;
            > i {
                margin-right: 7px;
            } 
        }  
        .owl-next {
            right: -30px; 
            display: flex!important;
            justify-content: flex-start;
            align-items: center;
            > i { 
                margin-left: 7px;
            }
        }
    }
}