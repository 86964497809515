.product {
    border: 1px solid @border!important;
    border-radius: 5px;
    margin-bottom: 5px;
    &--table {
        padding: 0 3px 5px 3px!important;
        width: 100%;
        max-width: 290px;
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 40px 0;
        .product__thumbnail {
            object-fit: contain;

            height: 180px;
        }
    }

    &__header {
        .product__title {
            font-size: 15px;
            line-height: 1.33;
            text-align: center;
            margin: 0;
            min-height: 60px;
            max-height: 60px;
            overflow: hidden;
        }
    }

    &__price-container { 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 45px;
        margin-bottom: 20px;
    }

    &__price {
        margin-bottom: 0!important;
        .price__gross {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            text-align: center;

            padding: 0 20px;

            .price__reduced {   
                font-size: 12px;
                padding: 5px;
            }

            .price__current {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    &__tags {
        .tags {
            display: flex;
            flex-direction: column;
            padding: 10px;
            .tag {
                width: 95px;
                color: white;
                border-radius: 40px;
                font-size: 13px;
                font-weight: bold;
                text-align: center;
                padding: 5px 0;
                &--sale {
                    background-color: @mainColor;
                }
                &--new {
                    background-color: black;
                }
            }
        }
    }

    &__button {
        min-height: 35px;
        .addtobasket {
            padding: 9px 29px;
        }
    }
    &.product--small {
        .product__container {

        }
        .product__image {
            margin: 30px 0;
            .product__thumbnail {
                height: 100px;
            }
        }
        .product__header {
            .product__title {
                font-size: 13px;
                line-height: 1.385;
            }
        }
        .product__price-container {
            flex-direction: column;
            min-height: unset;
        }
        .product__price {
            .price__gross {
                flex-direction: row;
                margin-bottom: 10px;

                .price__reduced {   
                    padding: 0 5px;
                }
            }
        }
        .product__button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .addtobasket {
                padding: 7px 27px;
            }
        }
        .product__tags {
            .tags {
                width: 85px;
               
                .tag {
                    font-size: 12px;
                    padding: 3px 0;
                }
            }
        }

    }
}